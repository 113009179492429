<template>
	<div
		:class="{ 'top-ranking--ranked': ranked, 'justify-content-between': rankingList.length == 3 }"
		class="top-ranking d-flex"
	>
		<TopRankingItem
			v-for="(rankingItem, index) in rankingList"
			:key="index"
			:skeleton="skeleton"
			:memberId="rankingItem.member_id"
			:name="rankingItem.member.name"
			:avatar="rankingItem.member.picture"
			:points="rankingItem.points"
			:medalList="rankingItem.medals"
			:position="rankingItem.ranking"
			:itsMe="rankingItem.its_me"
		/>
	</div>
</template>

<script>
import TopRankingItem from "@/components/TopRankingItem.vue";

export default {
	components: {
		TopRankingItem,
	},
	props: {
		rankingList: {
			type: Array,
			default: () => [],
		},
		ranked: {
			type: Boolean,
			default: false,
		},
		skeleton: {
			type: Boolean,
			default: false,
		},
	},
};
</script>

<style lang="scss" scoped>
.top-ranking {
	align-items: flex-end;
	gap: 20px;

	&--ranked {
		padding-top: 40px;

		#top-ranking-item-1 {
			order: 2;
			margin-bottom: 100px;
		}
		#top-ranking-item-2 {
			order: 1;
		}
		#top-ranking-item-3 {
			order: 3;
		}
	}
}

@media (max-width: 768px) {
	.top-ranking {
		align-items: center;
		flex-direction: column;
		gap: 50px;

		#top-ranking-item-1 {
			order: 1;
			margin-bottom: 0;
		}
		#top-ranking-item-2 {
			order: 2;
		}
		#top-ranking-item-3 {
			order: 3;
		}
	}
}
</style>
