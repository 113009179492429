<template>
	<div class="ranking-list">
		<TextPlay
			size="xs"
			class="mb-2"
			color="var(--text-color)"
			:text="$t('gamification.no_students')"
			:skeleton="skeleton"
			v-if="rankingList.length <= 0 && !skeleton"
		/>

		<TopRanking
			v-if="rankingList.length > 3 && !skeleton"
			class="mb-5"
			ranked
			:skeleton="skeleton"
			:rankingList="highlightedItems"
		/>

		<div v-show="!skeleton">
			<RankingItem
				v-for="(rankingItem, index) in itemsInNormalList"
				:key="index"
				class="mb-1"
				:memberId="rankingItem.member_id"
				:name="rankingItem.member.name"
				:avatar="rankingItem.member.picture"
				:position="rankingItem.ranking"
				:points="rankingItem.points"
				:itsMe="rankingItem.its_me"
				:medalList="rankingItem.medals"
				:skeleton="skeleton"
			/>
		</div>

		<template v-if="rankingList.length <= 0 || skeleton">
			<RankingItem
				v-for="(rankingItem, index) in 5"
				:key="index"
				:position="index + 1"
				class="mb-1"
				:skeleton="skeleton"
			/>
		</template>

		<TextPlay
			v-if="max && rankingList.length > max && !skeleton"
			class="mt-3"
			size="xs"
			:text="$t('gamification.limit_of_ranking', { limit: max })"
			:skeleton="skeleton"
		/>
	</div>
</template>

<script>
import RankingItem from "@/components/RankingItem.vue";
import TopRanking from "@/components/TopRanking.vue";

export default {
	components: {
		RankingItem,
		TopRanking,
	},
	props: {
		rankingList: {
			type: Array,
			default: () => [],
		},
		max: {
			type: [Number, String],
		},
		skeleton: {
			type: Boolean,
			default: false,
		},
	},
	methods: {
		itemPosition(position, index) {
			if (position) return position;

			return this.isRemaningList ? index + 4 : index + 1;
		},
	},
	computed: {
		highlightedItems() {
			return this.max ? this.filteredItemsByMaxProp.slice(0, 3) : this.rankingList.slice(0, 3);
		},
		remaningItems() {
			return this.max ? this.filteredItemsByMaxProp.slice(3) : this.rankingList.slice(3);
		},
		isRemaningList() {
			return this.rankingList.length > 3;
		},
		filteredItemsByMaxProp() {
			return this.rankingList.slice(0, this.max);
		},
		itemsInNormalList() {
			return this.isRemaningList ? this.remaningItems : this.rankingList;
		},
	},
};
</script>

<style lang="scss" scoped>
.ranking-list {
	max-width: 820px;
}
</style>
